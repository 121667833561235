'use client';
import { useCustomerSession } from '@wla/lib/hooks/customer-session/use-customer-session';
import { CustomerFavouritesContext } from '@wla/lib/hooks/favourites/customer-favourites-context';
import { useTranslations } from 'next-intl';
import { useContext } from 'react';
import { HeaderIconButton } from './header-icon-button';

export function MiniFavourites() {
  const t = useTranslations('common');
  const { favouriteIds } = useContext(CustomerFavouritesContext);
  const { customerSession } = useCustomerSession();

  return (
    <HeaderIconButton
      count={favouriteIds?.length}
      href={customerSession ? '/customer/favourites' : '/favourites'}
      label={t('favourites')}
      icon="w3-wishlist"
    />
  );
}
