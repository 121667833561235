'use client';
import { cn } from '@wla/lib/helpers/cn';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';

type CampaignBarMessageTogglerProps = {
  messagesCount: number;
  children: ReactNode | ReactNode[];
  interval?: number;
};

export function CampaignBarMessageToggler({
  messagesCount = 0,
  children,
  interval = 7000,
}: CampaignBarMessageTogglerProps) {
  const [messageNumber, setMessageNumber] = useState<number>(0);
  const intervalRef = useRef<number | null>(null);
  const timerFunc = useCallback(() => {
    setMessageNumber((oldMessageNumber) => {
      if (messagesCount > oldMessageNumber) {
        return oldMessageNumber + 1;
      }
      return 0;
    });
  }, [messagesCount]);

  const startInterval = useCallback(() => {
    intervalRef.current = window.setInterval(timerFunc, interval);
  }, [intervalRef, timerFunc, interval]);

  const clearIntervalTimer = useCallback(() => {
    if (intervalRef?.current) {
      window.clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, [intervalRef]);

  useEffect(() => {
    startInterval();
    return () => {
      clearIntervalTimer();
    };
  }, [startInterval, clearIntervalTimer]);

  return (
    <div
      onMouseEnter={clearIntervalTimer}
      onMouseLeave={startInterval}
      className={cn({ 'transition duration-500': messageNumber !== 0 })}
      style={{ transform: `translateY(-${(100 / (messagesCount + 1)) * messageNumber}%)` }}
    >
      {children}
    </div>
  );
}
