'use client';
import NiceModal from '@ebay/nice-modal-react';
import { Icon } from '@wla/components/ui/icon';
import { Link } from '@wla/components/ui/link';
import { useCustomerSession } from '@wla/lib/hooks/customer-session/use-customer-session';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';

export function MiniLogin() {
  const t = useTranslations();
  const { customerSession, isB2C } = useCustomerSession();

  return (
    <div>
      {customerSession ? (
        <Link
          className="group relative flex items-center text-current no-underline hover:text-current"
          href={isB2C ? '/customer/overview' : '/b2b/update'}
        >
          <Icon src="w3-profile" size="24" />
          <span className="hidden w-max pl-2.5 font-semibold group-hover:underline md:block">
            {t('common.my-page')}
          </span>
        </Link>
      ) : (
        <button
          className="group relative flex items-center text-current hover:text-current hover:no-underline"
          onClick={() => NiceModal.show(DRAWER_ID.LOGIN_NAVIGATION_DRAWER_ID)}
        >
          <Icon src="w3-profile" size="24" className="mb-2" />
          <span className="hidden pl-2.5 font-semibold group-hover:underline md:block">{t('forms.login')}</span>
        </button>
      )}
    </div>
  );
}
