import { SearchTransition } from '@wla/components/ui/search/search-transition';

type SearchOverlayProps = {
  isOpen: boolean;
  onClick: () => void;
};

export function SearchOverlay({ isOpen, onClick }: SearchOverlayProps) {
  if (!isOpen) return null;

  return (
    <SearchTransition show={isOpen}>
      <div className="fixed inset-0 z-40 bg-black bg-opacity-50" aria-hidden="true" onClick={onClick} />
    </SearchTransition>
  );
}
