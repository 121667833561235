'use client';

import { Carousel, CarouselItem } from '@wla/components/ui/carousel/carousel';
import Image from '@wla/components/ui/image';
import { ComboboxOption } from '@wla/components/ui/search/search-combobox/search-combobox-option';
import { SearchOption } from '@wla/components/ui/search/search-container';
import { useRecentlyViewed } from '@wla/lib/hooks/recently-viewed/use-recently-viewed';
import { useTranslations } from 'next-intl';

type RecentViewedSliderProps = {
  onSelect(result: SearchOption): void;
};

export function RecentViewedSlider({ onSelect }: RecentViewedSliderProps) {
  const t = useTranslations('common');
  const { getCookieProducts } = useRecentlyViewed();
  const products = getCookieProducts();

  if (!products?.length) return null;

  return (
    <div className="flex flex-col gap-0">
      <p className="font-semibold md:pb-4">{t('recently-viewed')}</p>
      <Carousel visibleItems={7} visibleItemsMd={6} visibleItemsSm={4}>
        {products.map(({ id, imageAlt, imageUrl, title, url }) => (
          <CarouselItem key={id} className="max-h-44 pr-2 md:m-0 md:pr-2">
            <ComboboxOption
              href={url}
              key={id}
              onClick={() => onSelect({ title, url })}
              className="group/recent m-0.5 cursor-pointer md:m-0"
            >
              <Image
                src={imageUrl}
                width={60}
                height={80}
                placeholder="empty"
                alt={imageAlt ?? ''}
                className="transistion-transform h-20 w-[60px] bg-gray-100 object-contain duration-300 group-hover/recent:scale-110 md:h-[97px] md:w-[80px]"
                draggable={false}
              />
            </ComboboxOption>
          </CarouselItem>
        ))}
      </Carousel>
    </div>
  );
}
