'use client';
import { JyskIconsId } from '@jysk/icons/font/jysk-icons-ts';
import { Icon } from '@wla/components/ui/icon';
import { Link } from '@wla/components/ui/link';
import { useEffect, useState } from 'react';

type HeaderIconButtonProps = {
  icon: JyskIconsId;
  label: string;
  count: number;
  counter?: boolean;
  href: string;
};

export function HeaderIconButton({ icon, label, count, counter = true, href }: HeaderIconButtonProps) {
  const [isClient, setIsClient] = useState(false);

  // to avoid hydration error caused by the div that displays count
  // solution from https://nextjs.org/docs/messages/react-hydration-error
  // Jira ticket for refactoring this: https://jysk.atlassian.net/browse/CG-26210
  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <Link href={href} className="group flex items-center text-current no-underline hover:text-current">
      <div className="relative">
        <Icon src={icon} size="24" />
        {isClient && counter && count > 0 && (
          <div className="z-1 absolute -right-2 top-0 flex h-4 w-auto min-w-4 items-center justify-center rounded-full bg-blue-300 px-1 text-xs font-bold text-blue-500">
            {count > 99 ? '99+' : count}
          </div>
        )}
      </div>
      <span className="hidden pl-2.5 font-semibold group-hover:underline md:block">{label}</span>
    </Link>
  );
}
