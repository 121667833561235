import { cn } from '@wla/lib/helpers/cn';
import { ReactNode } from 'react';

type ComboboxListProps = {
  isOpen: boolean;
  children: ReactNode;
  className?: string;
};

export function ComboboxList({ isOpen, children, className }: ComboboxListProps) {
  return isOpen ? (
    <div className={cn('absolute z-50 max-h-[80svh] w-full rounded-b-md bg-white pb-5 pt-2 empty:hidden', className)}>
      {children}
    </div>
  ) : null;
}
