import Link from 'next/link';
import { ReactNode } from 'react';

type ComboboxOptionProps = {
  children: ReactNode;
  onClick: () => void;
  className?: string;
  href?: string;
};

export function ComboboxOption({ children, onClick, className, href }: ComboboxOptionProps) {
  return (
    <>
      {href ? (
        <Link href={href ?? ''} onClick={onClick} className={className}>
          {children}
        </Link>
      ) : (
        <button onClick={onClick} className={className}>
          {children}
        </button>
      )}
    </>
  );
}
