'use client';

import NiceModal from '@ebay/nice-modal-react';
import { FavoriteStoreOpeningStatus } from '@wla/app/(cms)/(favorite-store)/favorite-store-opening-status';
import { OpeningInfo, getStoreOpeningData } from '@wla/app/(cms)/(favorite-store)/get-store-opening-data';
import { Icon } from '@wla/components/ui/icon';
import { useFavoriteStore } from '@wla/lib/hooks/favorite-store/use-favorite-store';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';

export function FavoriteStoreSelector() {
  const t = useTranslations();
  const { favoriteStore } = useFavoriteStore();
  const [storeStatus, setStoreStatus] = useState<OpeningInfo>();

  useEffect(() => {
    if (favoriteStore) {
      const openingData = getStoreOpeningData(favoriteStore.openingHours);

      if (openingData) {
        setStoreStatus(openingData);
      }
    }
  }, [favoriteStore]);

  return (
    <button
      type="button"
      className="group grid min-w-full cursor-pointer grid-cols-[auto_minmax(0,1fr)_auto] items-center gap-2 lg:min-w-fit"
      onClick={() => NiceModal.show(DRAWER_ID.FAVORITE_STORE_DRAWER)}
    >
      <Icon src="w3-marker" size="20" />
      {favoriteStore && storeStatus ? (
        <>
          <p className="mb-0.5 truncate text-left font-bold group-hover:underline">
            {t('common.jysk-store', {
              storeName: favoriteStore.name,
            })}
          </p>

          <FavoriteStoreOpeningStatus openingInfo={storeStatus} />
        </>
      ) : (
        <div className="flex items-center gap-2 truncate">
          <p className="truncate whitespace-nowrap font-semibold group-hover:underline">
            {t('favorite-store.select-your-jysk-store')}
          </p>
          <Icon src="w3-arrow-down" size="16" className="-mb-0.5 -ml-2 text-gray-500" />
        </div>
      )}
    </button>
  );
}
