'use client';

import IconElite from '@wla/components/icons/static/badge-elite-smiley.svg';
import IconEmaerket from '@wla/components/icons/static/badge-emaerket.svg';
import { usePublicConfig } from '@wla/lib/configs';
import Image from 'next/image';

const BadgeIcons = {
  emaerket: { icon: IconEmaerket, url: '', target: '' },
  smiley: { icon: IconElite, url: 'https://www.findsmiley.dk/131374', target: '_blank' },
};

export function FooterBadges() {
  const config = usePublicConfig();

  return (
    <div className="flex gap-4">
      {config.footerBadges.map((opt, index) => {
        const { url, target, icon: iconProps } = BadgeIcons[opt];

        if (url !== '') {
          return (
            <a key={`${url}-${index}`} href={url} target={target} title={opt}>
              <Image src={iconProps.src} width={iconProps.width} height={iconProps.height} alt={opt} />
            </a>
          );
        }
        return (
          <Image
            key={`${url}-${index}`}
            src={iconProps.src}
            width={iconProps.width}
            height={iconProps.height}
            alt={opt}
          />
        );
      })}
    </div>
  );
}
