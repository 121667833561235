'use client';

import { NewsletterSignupB2B } from '@wla/app/(cms)/newsletter/b2b/newsletter-signup-b2b';
import { useCustomerSession } from '@wla/lib/hooks/customer-session/use-customer-session';

export function NewsletterSignupFooterB2B() {
  const { isB2B } = useCustomerSession();

  if (!isB2B) return;
  return <NewsletterSignupB2B source="B2B Footer" />;
}
