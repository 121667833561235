'use client';

import IconAmericanExpress from '@wla/components/icons/static/payment-american-express.svg';
import IconBank from '@wla/components/icons/static/payment-bank.svg';
import IconBankcontact from '@wla/components/icons/static/payment-bankcontact.svg';
import IconCreditCard from '@wla/components/icons/static/payment-credit-card.svg';
import IconDankort from '@wla/components/icons/static/payment-dankort.svg';
import IconDiners from '@wla/components/icons/static/payment-diners.svg';
import ExpressBankIcon from '@wla/components/icons/static/payment-expressbank.svg';
import PaymentIconIcon from '@wla/components/icons/static/payment-icon.svg';
import IconIdeal from '@wla/components/icons/static/payment-ideal.svg';
import IconJcb from '@wla/components/icons/static/payment-jcb.svg';
import IconKlarna from '@wla/components/icons/static/payment-klarna.svg';
import IconMaestro from '@wla/components/icons/static/payment-maestro.svg';
import IconMastercard from '@wla/components/icons/static/payment-mastercard.svg';
import IconMobilepay from '@wla/components/icons/static/payment-mobilepay.svg';
import IconPaypal from '@wla/components/icons/static/payment-paypal.svg';
import IconPaytrail from '@wla/components/icons/static/payment-paytrail.svg';
import IconPlaceholder from '@wla/components/icons/static/payment-placeholder.svg';
import IconResurs from '@wla/components/icons/static/payment-resurs.svg';
import IconSwish from '@wla/components/icons/static/payment-swish.svg';
import IconTroy from '@wla/components/icons/static/payment-troy.svg';
import IconVipps from '@wla/components/icons/static/payment-vipps.svg';
import IconVisaElectron from '@wla/components/icons/static/payment-visa-electron.svg';
import IconVisa from '@wla/components/icons/static/payment-visa.svg';
import { usePublicConfig } from '@wla/lib/configs';
import Image from 'next/image';

const PaymentIcons = {
  americanexpress: IconAmericanExpress,
  bank: IconBank,
  bankcontact: IconBankcontact,
  creditcard: IconCreditCard,
  dankort: IconDankort,
  diners: IconDiners,
  expressbank: ExpressBankIcon,
  paymenticon: PaymentIconIcon,
  ideal: IconIdeal,
  jcb: IconJcb,
  klarna: IconKlarna,
  maestro: IconMaestro,
  mastercard: IconMastercard,
  mobilepay: IconMobilepay,
  paypal: IconPaypal,
  paytrail: IconPaytrail,
  placeholder: IconPlaceholder,
  resurs: IconResurs,
  swish: IconSwish,
  troy: IconTroy,
  vipps: IconVipps,
  visaelectron: IconVisaElectron,
  visa: IconVisa,
};

export function PaymentOptions() {
  const config = usePublicConfig();

  return (
    <div className="flex flex-wrap gap-2">
      {config.paymentIcons.map((opt, index) => {
        const iconProps = PaymentIcons[opt];

        if (!iconProps) {
          return null;
        }

        return (
          <div key={`${iconProps.src}-${index}`}>
            <Image src={iconProps.src} width={iconProps.width} height={iconProps.height} alt="" />
          </div>
        );
      })}
    </div>
  );
}
