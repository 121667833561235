'use client';

import { ProductCardCarousel } from '@wla/components/ui/product-card-carousel';
import { Wrapper } from '@wla/components/ui/wrapper';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { GetProducts200Data } from '@jysk/api-types/drupalApi';
import { OnlineAtpMap } from '@jysk/api-types/webSapApiV2/model';

export const RECENTLY_VIEWED_UPDATE_EVENT = 'recentlyViewedUpdate';

type RecentlyViewedProps = {
  isCheckout?: boolean;
};

export function RecentlyViewed({ isCheckout }: RecentlyViewedProps) {
  const t = useTranslations();
  const pathname = usePathname();
  const [productData, setProductData] = useState<GetProducts200Data>([]);
  const [storeAtp, setStoreAtp] = useState<OnlineAtpMap>();

  const isFrontPage = pathname === '/' || pathname === '/frontpage';

  useEffect(() => {
    if (isFrontPage) return;

    const fetchRecentlyViewed = async () => {
      const res = await fetch('/api/get-recently-viewed', { cache: 'no-store' });
      const json = await res.json();

      if (!json.success) return;

      setProductData(json.data);
      setStoreAtp(json.storeAtp);
    };

    fetchRecentlyViewed();

    const handleRecentlyViewedUpdated = () => {
      fetchRecentlyViewed();
    };

    window.addEventListener(RECENTLY_VIEWED_UPDATE_EVENT, handleRecentlyViewedUpdated);

    return () => {
      window.removeEventListener(RECENTLY_VIEWED_UPDATE_EVENT, handleRecentlyViewedUpdated);
    };
  }, []);

  if (!productData?.length || isFrontPage) return;

  return (
    <div className="bg-white py-8">
      <Wrapper isCheckout={isCheckout} className={cn({ 'w-full max-w-[1311px] py-5 xl:py-6': isCheckout })}>
        <div className="mb-4 text-3xl font-semibold md:text-5xl lg:mb-8">{t('common.recently-viewed')}</div>
        <ProductCardCarousel products={productData} refId="recentlyViewed" stockData={storeAtp} />
      </Wrapper>
    </div>
  );
}
