import { CookieSearch } from '@wla/app/(cms)/api/set-recently-search/route';
import { Link } from '@wla/components/ui/link';
import { SearchOption } from '@wla/components/ui/search/search-container';
import { SearchItem } from '@wla/components/ui/search/search-item';
import { useSearch } from '@wla/lib/hooks/search/use-search';
import { useTranslations } from 'next-intl';
import { startTransition, useOptimistic } from 'react';

type RecentSearchesProps = {
  onSelect: (result: SearchOption) => void;
};

export function RecentSearches({ onSelect }: RecentSearchesProps) {
  const t = useTranslations();
  const { getRecentlySearchQueries, clearRecentlySearchQueries } = useSearch();
  const recentlySearchQueries: CookieSearch[] = getRecentlySearchQueries();

  const [optimisticRecentlySearches, updateoOptimisticRecentlySearches] = useOptimistic(
    recentlySearchQueries,
    (currentState, optimisticValue: CookieSearch[]) => {
      return optimisticValue;
    },
  );

  async function handleClearRecentSearches() {
    updateoOptimisticRecentlySearches([]);
    await clearRecentlySearchQueries();
  }

  if (!optimisticRecentlySearches?.length) return null;

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <p className="font-semibold">{t('search.your-recent-searches')}</p>
        <Link as="button" onClick={() => startTransition(() => handleClearRecentSearches())}>
          {t('search.clear')}
        </Link>
      </div>
      {optimisticRecentlySearches.slice(0, 3).map((search, index) => (
        <SearchItem
          key={`${search.searchQuery}_${index}`}
          text={search.searchQuery}
          highlightedText={search.searchQuery}
          url={search.url ?? ''}
          iconSrc="w3-search"
          className="w-fit pl-0"
          onSelect={onSelect}
        />
      ))}
    </div>
  );
}
