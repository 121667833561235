'use client';
import { countOrderLinesInShoppingSession } from '@wla/lib/helpers/shopping-session';
import { useShoppingSession } from '@wla/lib/shopping-session/use-shopping-session';
import { useTranslations } from 'next-intl';
import { HeaderIconButton } from './header-icon-button';

export function MiniBasket() {
  const t = useTranslations('common');

  const { shoppingSession } = useShoppingSession();
  const totalInBasket = countOrderLinesInShoppingSession(shoppingSession);
  return <HeaderIconButton href="/checkout" count={totalInBasket} label={t('basket')} icon="w3-basket" />;
}
