'use client';

type UseDebounceProps = {
  debounceDelay: number;
  maxDelay: number;
  trigger?: (...args: any[]) => void;
};

let delayTimer: NodeJS.Timeout | null;
let maxTimer: NodeJS.Timeout | null;

export function useDebounce({ debounceDelay, maxDelay, trigger }: UseDebounceProps) {
  const setDelayTimer = (...args: any[]) =>
    setTimeout(() => {
      reset();
      trigger?.(...args);
    }, debounceDelay);

  const setMaxTimer = (...args: any[]) =>
    setTimeout(() => {
      reset();
      trigger?.(...args);
    }, maxDelay);

  const reset = () => {
    if (delayTimer) {
      clearTimeout(delayTimer);
      delayTimer = null;
    }

    if (maxTimer) {
      clearTimeout(maxTimer);
      maxTimer = null;
    }
  };

  return {
    delay: (...args: any[]) => {
      if (delayTimer) {
        clearTimeout(delayTimer);
      }

      delayTimer = setDelayTimer(...args);

      if (!maxTimer) {
        maxTimer = setMaxTimer(...args);
      }
    },
  };
}
