'use client';
import { GeneralNotice } from '@jysk/api-types/drupalApi';
import { Icon } from '@wla/components/ui/icon';
import { Link } from '@wla/components/ui/link';
import { GENERAL_NOTICE_ID } from '@wla/lib/constants';
import Cookies from 'js-cookie';
import { Fragment, useState } from 'react';

type GeneralNoticeBarElementProps = {
  generalNoticeBar: GeneralNotice;
  hashedMessage: string;
};

export function GeneralNoticeBarElement({ generalNoticeBar, hashedMessage }: GeneralNoticeBarElementProps) {
  const message = generalNoticeBar?.messages?.length > 0 ? generalNoticeBar?.messages[0] : null;
  const [hideComponent, setHideComponent] = useState<boolean>(false);

  function removeGeneralNoticeBar(message: string) {
    Cookies.set(GENERAL_NOTICE_ID, JSON.stringify(message));
    setHideComponent(true);
  }

  if (hideComponent || !message) return null;

  return (
    <div
      className="flex min-h-10 items-center justify-center md:h-10"
      style={{
        backgroundColor: generalNoticeBar.backgroundColor || '',
      }}
    >
      <Fragment key={message.text}>
        <div className="group relative flex w-10/12 content-center items-center justify-center gap-2 self-center">
          {message.button?.url ? (
            <Link
              href={message.button.url}
              className="group flex w-10/12 content-center items-center justify-center gap-2 self-center text-sm"
            >
              <span style={{ color: message.colors?.text || '' }} className="text-white group-hover:underline">
                {message.text}
              </span>
              {message.button.title ? (
                <span
                  style={{
                    backgroundColor: message.colors?.buttonBackground || '',
                    color: message.colors?.buttonText || '',
                  }}
                  className="bg-white px-1.5 py-0.5 group-hover:underline"
                >
                  {message.button.title}
                </span>
              ) : (
                <Icon size="10" src="arrow-right" />
              )}
            </Link>
          ) : (
            <p>{message.text}</p>
          )}
          <div
            className="absolute right-0 cursor-pointer"
            onClick={() => {
              removeGeneralNoticeBar(hashedMessage);
            }}
          >
            <Icon className="flex justify-items-end" size="12" src="close" />
          </div>
        </div>
      </Fragment>
    </div>
  );
}
