import { Icon } from '@wla/components/ui/icon';
import { SearchOption } from '@wla/components/ui/search/search-container';
import { cn } from '@wla/lib/helpers/cn';
import { scrollToOffset } from '@wla/lib/helpers/scroll-to';
import { useBreakpoint } from '@wla/lib/hooks/use-breakpoint';
import { useTranslations } from 'next-intl';
import { FocusEvent, InputHTMLAttributes, KeyboardEvent, RefObject, useCallback } from 'react';

type ComboboxInputProps = InputHTMLAttributes<HTMLInputElement> & {
  setIsOpen: (open: boolean) => void;
  inputRef: RefObject<HTMLInputElement>;
  onSearch: (option: SearchOption) => void;
  onReset?: () => void;
  isOpen?: boolean;
};

export function ComboboxInput({ setIsOpen, inputRef, onReset, value, isOpen, onSearch, ...props }: ComboboxInputProps) {
  const t = useTranslations('search');
  const { getBreakPoint } = useBreakpoint();
  const { valid: isLg } = getBreakPoint('lg');

  const handleScroll = useCallback(
    (event: FocusEvent<HTMLInputElement>) => {
      if (isLg) return;

      const paddingOffset = 10;
      const top = event.currentTarget.getBoundingClientRect().top + window.scrollY - paddingOffset;
      scrollToOffset(top);
    },
    [isLg],
  );

  function handleSearch(value: string) {
    onSearch({ url: `/search?query=${value}`, title: value });
    inputRef.current?.blur();
  }

  return (
    <div className={cn('relative z-50 rounded-t-md bg-white lg:p-4', { 'flex gap-4 p-4': !isLg && isOpen })}>
      <div className="grid w-full grid-flow-col auto-rows-min grid-cols-1 gap-x-2.5 rounded bg-gray-100">
        <input
          {...props}
          ref={inputRef}
          value={value}
          onFocus={(e) => {
            handleScroll(e);
            setIsOpen(true);
          }}
          className="w-full rounded border-none bg-gray-100 py-2.5 pl-5 pr-0 text-lg text-gray-900 focus:outline-none focus:ring-0"
          autoComplete="off"
          onKeyUp={(event: KeyboardEvent<HTMLInputElement>) => {
            if (event.key === 'Enter' && value) {
              handleSearch(value.toString());
            }
          }}
        />
        {value && (
          <button type="reset" className="z-50 text-sm text-gray-600 underline" onClick={onReset}>
            {t('clear')}
          </button>
        )}
        <button
          type="submit"
          className="z-50 mr-5 h-10 cursor-pointer"
          onClick={() => (value ? handleSearch(value.toString()) : undefined)}
        >
          <Icon src="w3-search" size="24" />
        </button>
      </div>
      {!isLg && isOpen && (
        <button onClick={() => setIsOpen(false)}>
          <Icon src="w3-close" size="24" />
        </button>
      )}
    </div>
  );
}
