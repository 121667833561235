'use client';

import { NewsletterSignupB2C } from '@wla/app/(cms)/newsletter/b2c/newsletter-signup-b2c';
import { useCustomerSession } from '@wla/lib/hooks/customer-session/use-customer-session';

export function NewsletterSignupFooterB2C() {
  const { isB2B } = useCustomerSession();

  if (isB2B) return;
  return <NewsletterSignupB2C source="B2C Footer" />;
}
