'use client';

import { Carousel, CarouselItem } from '@wla/components/ui/carousel/carousel';
import Image from '@wla/components/ui/image';
import { ComboboxOption } from '@wla/components/ui/search/search-combobox/search-combobox-option';
import { SearchOption } from '@wla/components/ui/search/search-container';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';
import { GetQuickSearch200Categories } from '@jysk/api-types/searchApi/model';

type SearchCategoryResultsProps = {
  categories?: GetQuickSearch200Categories | null;
  onSelect(result: SearchOption): void;
};

export function SearchCategoryResults({ categories, onSelect }: SearchCategoryResultsProps) {
  const t = useTranslations('common');

  if (!categories?.hits?.length) return null;

  return (
    <div className="flex flex-col gap-4">
      <p className="font-semibold">{t('categories')}</p>
      <div className="pl-4">
        <Carousel visibleItems={5} visibleItemsMd={5} visibleItemsSm={4}>
          {categories.hits.map(({ text, url, imageUrl, isTopLevelCategory }) => (
            <CarouselItem key={url} className="flex max-h-44 justify-center">
              <ComboboxOption
                key={url}
                onClick={() => onSelect({ title: text, url })}
                className="group/category-slider flex w-fit flex-col items-center gap-2"
                href={url}
              >
                <>
                  <div className="group/category-slider flex h-14 w-14 flex-shrink-0 cursor-pointer flex-nowrap items-center justify-center overflow-hidden rounded-full bg-gray-100">
                    <Image
                      src={imageUrl ?? ''}
                      width={60}
                      height={60}
                      placeholder="empty"
                      alt={text ?? ''}
                      className={cn('h-5/6 w-5/6 mix-blend-darken', {
                        'h-full w-full': isTopLevelCategory,
                        'object-contain': !isTopLevelCategory,
                      })}
                      draggable={false}
                    />
                  </div>
                  <p className="mt-0 w-full max-w-48 overflow-hidden break-all text-center text-sm text-black group-hover/category-slider:underline">
                    {text}
                  </p>
                </>
              </ComboboxOption>
            </CarouselItem>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
