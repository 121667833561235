import(/* webpackMode: "eager", webpackExports: ["GeneralNoticeBarElement"] */ "/workdir/apps/wla/app/(cms)/[...slug]/general-notice-bar/general-notice-bar-element.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CampaignBarElement"] */ "/workdir/apps/wla/app/(cms)/campaign-bar/campaign-bar-element.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FooterBadges"] */ "/workdir/apps/wla/app/(cms)/footer-badges.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MiniBasket"] */ "/workdir/apps/wla/app/(cms)/mini-basket.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MiniFavourites"] */ "/workdir/apps/wla/app/(cms)/mini-favourites.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MiniLogin"] */ "/workdir/apps/wla/app/(cms)/mini-login.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navigation"] */ "/workdir/apps/wla/app/(cms)/navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsletterSignupFooterB2B"] */ "/workdir/apps/wla/app/(cms)/newsletter/b2b/newsletter-signup-footer-b2b.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NewsletterSignupFooterB2C"] */ "/workdir/apps/wla/app/(cms)/newsletter/b2c/newsletter-signup-footer-b2c.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PaymentOptions"] */ "/workdir/apps/wla/app/(cms)/payment-options.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["RecentlyViewed"] */ "/workdir/apps/wla/app/(cms)/recently-viewed.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SecondaryNavigation"] */ "/workdir/apps/wla/app/(cms)/secondary-navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion"] */ "/workdir/apps/wla/components/ui/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["EmbeddedContent"] */ "/workdir/apps/wla/components/ui/embedded-content/embedded-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Home"] */ "/workdir/apps/wla/components/ui/home.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ParagraphItemWrapper"] */ "/workdir/apps/wla/components/ui/paragraph/paragraph-item-wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SearchContainer"] */ "/workdir/apps/wla/components/ui/search/search-container.tsx");
;
import(/* webpackMode: "eager" */ "/workdir/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/workdir/node_modules/next/dist/client/link.js");
