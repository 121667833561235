import { GetQuickSearch200Stores } from '@jysk/api-types/searchApi/model';
import { SearchOption } from '@wla/components/ui/search/search-container';
import { SearchItem } from '@wla/components/ui/search/search-item';
import { useTranslations } from 'next-intl';

type StoreResultsProps = {
  onSelect: (option: SearchOption) => void;
  stores?: GetQuickSearch200Stores;
};

export function SearchStoreResults({ stores, onSelect }: StoreResultsProps) {
  const t = useTranslations();

  if (!stores?.hits?.length) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <p className="font-semibold">{t('search.capital-stores')}</p>
      {stores.hits.map((store, index) => {
        return (
          <SearchItem
            key={`${store.text}_${index}`}
            text={t('common.jysk-store', { storeName: store.text })}
            highlightedText={t('common.jysk-store', { storeName: store.highlighted })}
            url={store.url}
            iconSrc="w3-click-and-collect"
            onSelect={onSelect}
          />
        );
      })}
    </div>
  );
}
