'use client';

import { FavoriteStoreSelector } from '@wla/app/(cms)/(favorite-store)/favorite-store-selector';
import { Link } from '@wla/components/ui/link';
import { MenuItems } from '@jysk/api-types/drupalApi';

type HeaderSecondaryNavigationProps = {
  menu: MenuItems;
};

export function SecondaryNavigation({ menu }: HeaderSecondaryNavigationProps) {
  return (
    <div className="w-full border-b pb-4 lg:flex lg:flex-row lg:items-center lg:justify-between lg:border-t lg:pt-4">
      <FavoriteStoreSelector />
      <div className="scrollbar-hide hidden snap-x snap-mandatory overflow-x-auto lg:flex">
        {menu &&
          menu.map(({ url = '', title }, index) => (
            <Link
              key={`${title}-${index}`}
              href={url}
              className="flex snap-start items-end justify-center text-gray-500 no-underline hover:underline"
            >
              <div className="flex cursor-pointer flex-col items-center pl-5">
                <div className="whitespace-nowrap text-sm">{title}</div>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}
