import { Icon } from '@wla/components/ui/icon';
import { ComboboxOption } from '@wla/components/ui/search/search-combobox/search-combobox-option';
import { SearchOption } from '@wla/components/ui/search/search-container';
import { Text } from '@wla/components/ui/text';
import { cn } from '@wla/lib/helpers/cn';
import { JyskIconsId } from '@jysk/icons/font/jysk-icons-ts';

type SearchItemProps = {
  text: string;
  highlightedText: string;
  url: string;
  iconSrc: JyskIconsId;
  onSelect: (option: SearchOption) => void;
  className?: string;
};

export function SearchItem({ text, highlightedText, url, iconSrc, className, onSelect }: SearchItemProps) {
  return (
    <ComboboxOption
      href={url}
      className={cn('group/search-item flex cursor-pointer content-center gap-4 pl-2', className)}
      onClick={() => onSelect({ title: text, url })}
    >
      <Icon src={iconSrc} size="20" />
      <Text
        className="flex gap-x-1 self-center text-start text-base group-hover/search-item:underline"
        value={highlightedText}
      />
    </ComboboxOption>
  );
}
