import { Transition } from '@headlessui/react';
import { PropsWithChildren } from 'react';

export function SearchTransition({ children, show }: PropsWithChildren<{ show: boolean }>) {
  return (
    <Transition
      enter="transition ease-out duration-300"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      show={show}
    >
      {children}
    </Transition>
  );
}
