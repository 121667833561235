import { GetQuickSearch200Contents } from '@jysk/api-types/searchApi/model';
import { SearchOption } from '@wla/components/ui/search/search-container';
import { SearchItem } from '@wla/components/ui/search/search-item';
import { useTranslations } from 'next-intl';

type SearchContentResultsProps = {
  onSelect: (option: SearchOption) => void;
  content?: GetQuickSearch200Contents;
};

export function SearchContentResults({ content, onSelect }: SearchContentResultsProps) {
  const t = useTranslations('search');

  if (!content?.hits?.length) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <p className="font-semibold">{t('content')}</p>
      {content.hits?.map((content, index) => (
        <SearchItem
          key={`${content.text}_${index}`}
          text={content.text}
          highlightedText={content.highlighted}
          url={content.url}
          iconSrc="w3-inspiration"
          onSelect={onSelect}
        />
      ))}
    </div>
  );
}
