'use client';
import NiceModal from '@ebay/nice-modal-react';
import { MenuItems } from '@jysk/api-types/drupalApi';
import { Icon } from '@wla/components/ui/icon';
import { DRAWER_ID } from '@wla/lib/modals';
import { useTranslations } from 'next-intl';
import { useCallback } from 'react';

type NavigationProps = { items: MenuItems; secondaryItems: MenuItems };

export function Navigation({ items, secondaryItems }: NavigationProps) {
  const t = useTranslations('common');

  const handleNavigationDrawer = useCallback(() => {
    NiceModal.show(DRAWER_ID.NAVIGATION_DRAWER_ID, { items, secondaryItems });
  }, [items, secondaryItems]);

  return (
    <button
      aria-label={t('menu')}
      aria-haspopup="menu"
      className="group flex cursor-pointer flex-row items-center gap-2.5"
      onClick={handleNavigationDrawer}
    >
      <Icon src="w3-menu" size="24" aria-label={`${t('menu')} icon`} />
      <span className="hidden font-semibold group-hover:underline md:block">{t('menu')}</span>
    </button>
  );
}
