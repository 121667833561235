'use client';

import { CampaignBarMessageToggler } from '@wla/app/(cms)/campaign-bar/campaign-bar-message-toggler';
import { Icon } from '@wla/components/ui/icon';
import { Link } from '@wla/components/ui/link';
import { cn } from '@wla/lib/helpers/cn';
import { usePathname } from 'next/navigation';
import { ElementType, PropsWithChildren } from 'react';
import { CampaignBar, MessagesParagraph } from '@jysk/api-types/drupalApi';

type CampaignBarElementProps = { campaignBar: CampaignBar };

export function CampaignBarElement({ campaignBar }: CampaignBarElementProps) {
  const path = usePathname();
  if (path === '/' && !campaignBar.showOnFrontPage) return null;

  if (!campaignBar?.messages?.length) return null;

  return (
    <div
      style={{ backgroundColor: campaignBar.backgroundColor || '' }}
      className="h-9 overflow-hidden bg-brand-500 text-center"
    >
      <CampaignBarMessageTogglerWrapper messages={campaignBar.messages}>
        {campaignBar.messages.map((message) => {
          const MessageItem: ElementType = message.button?.url ? Link : 'div';
          const customTextColor = message.colors?.text;

          return (
            <div key={message.text} className="flex h-9 items-center justify-center text-center">
              <MessageItem
                {...(message?.button?.url && { href: message.button.url })}
                className="group flex w-10/12 content-center items-center justify-center gap-2 self-center text-sm"
              >
                <span
                  {...(customTextColor && { style: { color: customTextColor } })}
                  className={cn('text-white', message?.button?.url && 'group-hover:underline')}
                >
                  {message.text}
                </span>
                {message?.button?.title ? (
                  <span
                    style={{
                      backgroundColor: message.colors?.buttonBackground || '',
                      color: message.colors?.buttonText || '',
                    }}
                    className={cn('bg-white px-1.5 py-0.5', message?.button?.url && 'group-hover:underline')}
                  >
                    {message.button.title}
                  </span>
                ) : (
                  <Icon
                    size="10"
                    src="arrow-right"
                    className="text-white"
                    {...(customTextColor && { style: { color: customTextColor } })}
                  />
                )}
              </MessageItem>
            </div>
          );
        })}
      </CampaignBarMessageTogglerWrapper>
    </div>
  );
}

export function CampaignBarMessageTogglerWrapper({
  messages,
  children,
}: PropsWithChildren<{ messages: MessagesParagraph[] }>) {
  if (messages.length > 1)
    return <CampaignBarMessageToggler messagesCount={messages.length - 1}>{children}</CampaignBarMessageToggler>;

  return <>{children}</>;
}
