import { SearchOverlay } from '@wla/components/ui/search/search-overlay';
import { cn } from '@wla/lib/helpers/cn';
import { Children, ReactNode, cloneElement, isValidElement, useEffect, useRef } from 'react';
import { useScrollLock } from 'usehooks-ts';

interface ComboboxProps {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

export function Combobox({ children, className, isOpen, setIsOpen }: ComboboxProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const { lock, unlock } = useScrollLock({ autoLock: false });

  function handleClickOutside(event: MouseEvent) {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setIsOpen(false);
      inputRef.current?.blur();
    }
  }

  useEffect(() => {
    if (isOpen) {
      lock();
    } else {
      unlock();
    }
  }, [isOpen]);

  function handleOverlayClick() {
    setIsOpen(false);
    inputRef.current?.blur();
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={wrapperRef} className={cn('relative', className)}>
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { isOpen, setIsOpen, inputRef } as Partial<typeof child.props>);
        }
        return child;
      })}
      <SearchOverlay isOpen={isOpen} onClick={handleOverlayClick} />
    </div>
  );
}
