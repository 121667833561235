'use client';
import { CookieProduct } from '@wla/app/(cms)/api/set-recently-viewed/route';
import { RECENTLY_VIEWED_COOKIE_KEY } from '@wla/lib/constants';
import { useAtp } from '@wla/lib/hooks/use-atp';
import Cookies from 'js-cookie';

export function useRecentlyViewed() {
  const cookie = Cookies.get(RECENTLY_VIEWED_COOKIE_KEY);
  const cookieProducts = JSON.parse(cookie ?? '[]') as CookieProduct[];

  const { fetchOnlineWithStoreStatusAtp } = useAtp();

  async function getRecentlyViewedProducts() {
    if (!cookieProducts.length) return { products: [], stockData: null };

    const productIds = cookieProducts.map((product) => product.id);
    const response = await fetch(`/checkout/api/get-products`, {
      method: 'POST',
      body: JSON.stringify({ productIds }),
    });

    const data = await response.json();
    const stockData = await fetchOnlineWithStoreStatusAtp(productIds);

    return { products: data.products || [], stockData };
  }

  function getCookieProducts() {
    return cookieProducts;
  }

  return { getCookieProducts, getRecentlyViewedProducts };
}
