import { ComboboxOption } from '@wla/components/ui/search/search-combobox/search-combobox-option';
import { SearchOption } from '@wla/components/ui/search/search-container';
import { Text } from '@wla/components/ui/text';
import { cn } from '@wla/lib/helpers/cn';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { GetQuickSearch200Products } from '@jysk/api-types/searchApi/model';

type SearchProductResultsProps = {
  products?: GetQuickSearch200Products;
  onSelect: (option: SearchOption) => void;
};

export function SearchProductResults({ products, onSelect }: SearchProductResultsProps) {
  const t = useTranslations();

  if (!products?.hits?.length) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <p className="font-semibold">{t('search.capital-products')}</p>
      {products.hits.map(({ key, discount, price, text, url, image, series, highlighted }) => (
        <ComboboxOption
          key={key}
          onClick={() => onSelect({ title: text, url })}
          className="group/product-option flex cursor-pointer items-center gap-4"
          href={url}
        >
          <div className="flex h-12 w-9 justify-center overflow-hidden bg-gray-100 p-0.5 align-middle">
            <Image src={image ?? ''} alt={text} className="h-full w-full object-scale-down" width={30} height={40} />
          </div>
          <div className="flex h-full flex-1 flex-col items-start group-hover/product-option:underline">
            <p className="text-sm font-bold uppercase">{series}</p>
            <Text className="text-start text-sm text-gray-700" value={highlighted} />
          </div>
          <div className="flex flex-col-reverse items-end sm:flex-row sm:gap-2">
            {Boolean(discount) && (
              <p className="w-fit rounded bg-red-500 px-1 py-0.5 text-xs font-semibold text-white">
                {t('common.discount', { discount })}
              </p>
            )}
            <p
              className={cn('text-sm font-bold group-hover/product-option:underline', {
                'text-red-500': Boolean(discount),
              })}
            >
              {price}
            </p>
          </div>
        </ComboboxOption>
      ))}
    </div>
  );
}
