'use client';
import { GetQuickSearch200 } from '@jysk/api-types/searchApi/model/getQuickSearch200';
import { Link } from '@wla/components/ui/link';
import { RecentSearches } from '@wla/components/ui/search/recent-searches';
import { RecentViewedSlider } from '@wla/components/ui/search/recent-viewed-slider';
import { Combobox } from '@wla/components/ui/search/search-combobox/search-combobox';
import { ComboboxInput } from '@wla/components/ui/search/search-combobox/search-combobox-input';
import { ComboboxList } from '@wla/components/ui/search/search-combobox/search-combobox-list';
import { SearchCategoryResults } from '@wla/components/ui/search/search-results/search-category-results';
import { SearchContentResults } from '@wla/components/ui/search/search-results/search-content-results';
import { SearchProductResults } from '@wla/components/ui/search/search-results/search-product-results';
import { SearchStoreResults } from '@wla/components/ui/search/search-results/search-store-results';
import { useSearch } from '@wla/lib/hooks/search/use-search';
import { useDebounce } from '@wla/lib/hooks/use-debounce';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
import { useCallback, useMemo, useRef, useState } from 'react';

export type SearchOption = {
  url: string;
  title: string;
};

export function SearchContainer() {
  const t = useTranslations('search');
  const { push } = useRouter();
  const [query, setQuery] = useState('');
  const [searchResults, setSearchResults] = useState<GetQuickSearch200>();
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { quickSearch, setRecentlySearchedQuery } = useSearch();
  const { delay } = useDebounce({
    debounceDelay: 300,
    maxDelay: 500,
    trigger: (query: string) => fetchQuickSearch(query),
  });

  const hasResults = useMemo(
    () => Boolean(Object.values(searchResults || {}).filter((result) => (result.hits ?? []).length > 0).length),
    [searchResults],
  );

  const handleOptionClick = useCallback(
    ({ title, url }: SearchOption) => {
      setRecentlySearchedQuery(title, url);
      inputRef.current?.blur();
      setIsOpen(false);
      push(url);
    },
    [setRecentlySearchedQuery, setIsOpen, push, inputRef],
  );

  async function fetchQuickSearch(query: string) {
    if (!query.length) {
      setSearchResults(undefined);
    }

    if (query.length <= 2) return;

    const response = await quickSearch(query);
    setSearchResults(response);
  }

  return (
    <Combobox className="group/combobox w-full lg:max-w-[35.25rem]" isOpen={isOpen} setIsOpen={setIsOpen}>
      <ComboboxInput
        value={query}
        onChange={(e) => {
          setQuery(e.target.value);
          if (!e.target.value || e.target.value.length <= 2) {
            setSearchResults(undefined);
          } else {
            delay(e.target.value);
          }
        }}
        setIsOpen={setIsOpen}
        placeholder={t('input-placeholder')}
        onReset={() => {
          setQuery('');
          setSearchResults(undefined);
        }}
        inputRef={inputRef}
        onSearch={handleOptionClick}
      />

      <ComboboxList isOpen={isOpen} className="overflow-y-auto">
        <div className="flex flex-col gap-4 px-4">
          {!hasResults && (query.length <= 2 || !searchResults) && (
            <div className="flex flex-col gap-8">
              <RecentSearches onSelect={handleOptionClick} />
              <RecentViewedSlider onSelect={handleOptionClick} />
            </div>
          )}

          {!hasResults && query.length > 2 && searchResults && <p className="font-semibold">{t('no-results')}</p>}

          <SearchCategoryResults categories={searchResults?.categories} onSelect={handleOptionClick} />
          <SearchProductResults products={searchResults?.products} onSelect={handleOptionClick} />
          <SearchStoreResults stores={searchResults?.stores} onSelect={handleOptionClick} />
          <SearchContentResults content={searchResults?.contents} onSelect={handleOptionClick} />

          {hasResults && (
            <Link
              as="button"
              onClick={() => handleOptionClick({ title: query, url: `/search?query=${query}` })}
              className="w-fit self-center"
            >
              <p>{t('see-all-results')}</p>
            </Link>
          )}
        </div>
      </ComboboxList>
    </Combobox>
  );
}
